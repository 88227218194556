define("sc-portal/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "e45Xx3Tc",
    "block": "{\"symbols\":[\"newVersion\",\"oldVersion\",\"reload\"],\"statements\":[[5,\"ember-notify\",[],[[\"@messageStyle\",\"@closeAfter\"],[\"bootstrap\",\"4000\"]]],[0,\"\\n\"],[5,\"sc-sticky\",[],[[\"@type\",\"@stickTo\"],[\"destination\",\"top\"]],{\"statements\":[[0,\"\\n  \"],[5,\"app-nav-primary\",[],[[\"@appIsLoading\"],[[24,0,[\"currentlyLoading\"]]]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[4,\"unless\",[[24,0,[\"isTest\"]]],null,{\"statements\":[[0,\"  \"],[5,\"new-version-notifier\",[],[[\"@updateInterval\"],[\"600000\"]],{\"statements\":[[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"custom-notification alert alert-warning text-center\"],[9],[0,\"\\n      \"],[7,\"p\"],[9],[0,\"\\n        This application has been updated from version\\n        \"],[1,[29,\"if\",[[29,\"or\",[[29,\"not\",[[24,2,[\"length\"]]],null],[29,\"gt\",[[24,2,[\"length\"]],20],null]],null],\"[unknown]\",[24,2,[]]],null],false],[0,\"\\n        to\\n        \"],[1,[29,\"if\",[[29,\"or\",[[29,\"not\",[[24,1,[\"length\"]]],null],[29,\"gt\",[[24,1,[\"length\"]],20],null]],null],\"[unknown]\",[24,1,[]]],null],false],[0,\".\\n        \"],[7,\"br\"],[9],[10],[0,\"\\n        Please save your work and reload the page to see the changes.\\n      \"],[10],[0,\"\\n      \"],[5,\"sc-button\",[],[[\"@label\",\"@color\",\"@onClick\"],[\"Reload\",\"primary\",[29,\"action\",[[24,0,[]],[24,0,[\"reload\"]]],null]]]],[0,\"\\n    \"],[10],[0,\"\\n  \"]],\"parameters\":[1,2,3]}],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"main\"],[11,\"class\",\"pt-2 d-flex flex-grow-1 overflow-y-scroll\"],[9],[0,\"\\n  \"],[1,[23,\"outlet\"],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sc-portal/templates/application.hbs"
    }
  });

  _exports.default = _default;
});